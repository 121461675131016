/**
 * @author  XuHongli
 * @date  2023/1/10 15:43
 * @version 1.0
 * @description
 */
export const columns = [
  {
    title: '账单ID',
    dataIndex: 'id',
  },
  {
    title: '账单日期',
    dataIndex: 'date'
  },
  {
    title: '账单类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '税率',
    dataIndex: 'taxRate',
    scopedSlots: { customRender: 'taxRate' }
  },
  {
    title: '待开票金额',
    dataIndex: 'makeMoney'
  },
  {
    title: '开票状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '100px',
    scopedSlots: { customRender: 'action' }
  }
]
