<template>
  <div class="InvoiceList bg-white p-10">
    <div class="flex-row flex-row-between pb-20 invoiceAmount">
      <div class="box mr-5">
        <div class="flex-row flex-col-center">
          <div class="applyLeft">
            <img alt="" src="https://file.jingpingo.com/supply/material/0f0566da-b15d-47fe-a97a-b4664c24a20e.png">
          </div>
          <div class="applyRight">
            <p class="t1">￥{{ amount.waitMoney === null ? '0.00' : amount.waitMoney }}</p>
            <p class="t2">
              <span>待开票金额（元）</span>
            </p>
          </div>
        </div>
        <p class="tips">注：账单为每月18日，即每月18日结算上月待开票金额</p>
      </div>
      <div class="box ml-5">
        <div class="flex-row flex-col-center">
          <div class="applyLeft">
            <img alt="" src="https://file.jingpingo.com/supply/material/c9111339-91f7-4956-bf5f-477dc0e2d9f6.png">
          </div>
          <div class="applyRight">
            <p class="t1">￥{{ amount.makeMoney === null ? '0.00' : amount.makeMoney }}</p>
            <p class="t2">
              <span>已开票金额（元）</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 条件  -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="10">
          <a-col :md="8" :sm="24">
            <a-form-item label="开票状态">
              <a-select v-model="queryParam.state" placeholder="请选择开票状态" size="small">
                <a-select-option :value="1">待开票</a-select-option>
                <a-select-option :value="2">申请开票</a-select-option>
                <a-select-option :value="3">已开票</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="日期">
              <a-range-picker ref="rangePicker" v-model="selectTime" :ranges="{ '今天': [moment(), moment()], '本月': [moment().startOf('month'), moment().endOf('month')] }"
                size="small"
                @change="rangePickerOnChange" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button size="small" type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            <a-button size="small" style="margin-left: 8px"
              @click="() => { this.selectTime = []; this.queryParam = {} }">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

    </div>

    <s-table ref="table" :columns="columns" :data="loadData" :showPagination="'auto'" bordered rowKey="id" size="middle">
      <template slot="state" slot-scope="text, record">
        {{ record.state | InvoiceStateFilter }}
      </template>
      <template slot="action" slot-scope="text, record">
        <a v-if="record.state === 1" class="text-primary" href="javascript:void(0)"
          @click="applyInvoice(record.id)">申请开票</a>
        <a v-if="record.state === 3" :href="record.invoicePic" class="text-primary" target="_blank">查看发票</a>
      </template>
      <template slot="taxRate" slot-scope="text">
        {{ Number(text) * 100 + '%' }}
      </template>
      <template slot="type" slot-scope="text">
        {{ text | InvoiceTypeFilter }}
      </template>

    </s-table>
  </div>
</template>

<script>
import moment from 'moment'
import { applyInvoice, getInvoiceRecodePage } from '@/api/Invoice'
import STable from '@/components/Table'
import { columns } from './columns'
import InvoiceTypeMap from '@/Map/InvoiceTypeMap'
import { getUserInfoRz } from '@/api/Login'
import InvoiceStateMap from '@/Map/InvoiceStateMap'
/**
 * @author  XuHongli
 * @date  2023/1/10 15:17
 * @version 1.0
 * @description
 */


export default {
  name: 'InvoiceList',
  components: {
    STable
  },
  filters: {
    InvoiceTypeFilter(type) {
      return type === null ? '' : InvoiceTypeMap[type].text
    },
    InvoiceStateFilter(type) {
      return type === null ? '' : InvoiceStateMap[Number(type)].text
    }
  },
  created() {
    this.getInvoiceAmount()
  },
  data() {
    return {
      columns,
      moment,
      selectTime: [],
      amount: {
        waitMoney: 0,
        makeMoney: 0
      },
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象,
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return getInvoiceRecodePage(requestParameters).then((res) => {
          return res.data
        })
      }
    }
  },
  methods: {
    /**
     * 日期选择器回调
     * @param dates
     * @param dateStrings
     */
    rangePickerOnChange(dates, dateStrings) {
      this.queryParam.startTime = dateStrings[0]
      this.queryParam.endTime = dateStrings[1]
    },
    applyInvoice(id) {
      this.$confirm('确认开票?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        applyInvoice(id).then((res) => {
          this.$message.success(res.msg)
          this.$refs.table.refresh(true)
        })
      })
    },
    // 获取未开票金额
    getInvoiceAmount() {
      getUserInfoRz().then(res => {
        this.amount.makeMoney = res.data.makeMoney
        this.amount.waitMoney = res.data.waitMoney
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.invoiceAmount {
  .box {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 21px 0 21px 30px;
    font-size: 14px;
    color: #555;
    border: 1px solid #eee;
    background: url("https://file.jingpingo.com/supply/material/abcaf5f5-a4b4-430b-87a9-8104b665c97d.png") 100% no-repeat;

    .applyLeft {
      width: 68px;
      height: 68px;

      img {
        width: 100%;
      }
    }

    .applyRight {
      margin-left: 20px;

      .t1 {
        margin-bottom: 9px;
        font-size: 24px;
        line-height: 24px;
        font-weight: 700;
        color: #2b2b2b;
      }

      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        padding: 0;
      }
    }
  }

  .tips {
    position: absolute;
    right: 10px;
    bottom: 3px;
    color: #aaa;
    font-size: 12px;
  }
}
</style>
