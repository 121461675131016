/**
 * @author  XuHongli
 * @date  2023/1/10 15:53
 * @version 1.0
 * @description
 */

const InvoiceTypeMap = {
  1: {
    text: '增值税专用发票'
  },
  2: {
    text: '增值税普通发票'
  },

}

export default InvoiceTypeMap
