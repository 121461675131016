/**
 * @author  XuHongli
 * @date  2023/1/10 15:53
 * @version 1.0
 * @description
 */

const InvoiceStateMap = {
  1: {
    text: '待开票'
  },
  2: {
    text: '审核中'
  },
  3: {
    text: '已开票'
  }
}

export default InvoiceStateMap
